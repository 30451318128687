// grease-client/src/components/CategoryDetails.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useStore from "../zustand/useStore";

function CategoryDetails() {
  const { entryList, fetchCategoryEntries } = useStore();
  const location = useLocation();
  const category = location.state.category;

  useEffect(() => {
    fetchCategoryEntries(category?.title);
  }, [category, fetchCategoryEntries]);

  return (
    <React.Fragment>
      <div className="flex">
        <div className="w-full ml-14">
          <h1 className="font-bold text-4xl">{category.title}</h1>
          <hr className="mt-4 w-3/4 bg-black h-1"></hr>
          <div className="w-3/4">
            <ol>
              {entryList.map((entry) => (
                <li className="mt-6" key={entry.id}>
                  <ul className="m-2" type="1">
                    <li className="font-bold text-2xl ">
                      <a
                        className="transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400"
                        href={entry.link}
                      >
                        {entry.title}
                      </a>
                    </li>
                    <li className="text-gray-700 text-sm mt-2">
                      {entry.publication} -{" "}
                      {entry.entryDate.toDate().toLocaleDateString()}
                    </li>
                    <li className="text-gray-700">{entry.description}</li>
                  </ul>
                  <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CategoryDetails;
