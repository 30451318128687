import React, { useState, useEffect } from "react";

function TimeStamps(props) {
  const [result, setResult] = useState([]);

  const videoList = props.videoList;
  const timeStampList = props.timeStampList;
  const timeStampLoadingState = props.timeStampLoadingState;
  const loadingState = props.loadingState;

  //sorts vids by latest date
  videoList.sort(function (a, b) {
    return new Date(a.meetingDate) - new Date(b.meetingDate);
  });

  // finds most recent entry
  const recent = videoList.length - 1;

  //sorts timestamp by time
  timeStampList.sort((a, b) => a.time.localeCompare(b.time));

  // ties time stamps with thier associated videos
  useEffect(() => {
    const videoTimes = [];
    timeStampList.forEach((time) => {
      if (time.videoId === videoList[recent].title) {
        videoTimes.push(time);
      }
      setResult(videoTimes);
    });
  }, []);

  //waits for data to load before rendering
  let test;
  if (!timeStampLoadingState && !loadingState) {
    test = (
      <>
        {Object.values(result).map((entry) => (
          <>
            <span className="flex mt-2">
              <strong className="">{entry.time}</strong>
              <p>&nbsp;-&nbsp;</p>
              <p className="">{entry.description}</p>
            </span>
          </>
        ))}
      </>
    );
  }

  return (
    <React.Fragment>
      <div>
        <p className="mt-5 header text-2xl font-bold header-title">
          Current City Council Notes - {videoList[recent].meetingDate}
        </p>

        <hr className="bg-black h-1 mt-4 mb-6"></hr>
      </div>
      <div className="overflow-auto break-words text-start mt-5">
        {!timeStampLoadingState && !loadingState ? test : null}
      </div>
    </React.Fragment>
  );
}

export default TimeStamps;
