import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Home,
  CategoryDetails,
  Header,
  Email,
  CategoryList,
  Notes,
  VideoEmbed,
} from "./components";
import Categories from "./components/Categories";
import useStore from "./zustand/useStore";

function App() {
  const {
    categoryList,
    entryList,
    timeStampList,
    videoList,
    loadingState,
    timeStampLoadingState,
    initializeData,
  } = useStore();

  useEffect(() => {
    initializeData();
  }, []);

  return (
    <Router>
      <Header />
      <div className="bg-white min-h-screen flex flex-col">
        <div className="flex-grow lg:grid lg:grid-cols-12 lg:gap-10 lg:px-24 lg:mt-10">
          <div className="lg:col-span-9">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    videoList={videoList}
                    loadingState={loadingState}
                    timeStampList={timeStampList}
                    timeStampLoadingState={timeStampLoadingState}
                    entryList={entryList}
                    categoryList={categoryList}
                  />
                }
              />
              <Route
                path="/category/:id"
                element={<CategoryDetails entryList={entryList} />}
              />
              <Route path="/contact" element={<Email />} />
              <Route path="/notes" element={<Notes />} />
            </Routes>
          </div>
          <div className="hidden md:block lg:col-span-3">
            <CategoryList categoryList={categoryList} />
          </div>
        </div>
        <div className="text-end fixed bottom-0 right-0">
          <Link to="/contact">
            <button className="border cursor-pointer bg-white border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold py-2 px-6 mr-4">
              Contact
            </button>
          </Link>
        </div>
      </div>
    </Router>
  );
}

export default App;
