// grease-client/src/zustand/useStore.js
import { create } from "zustand";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

const useStore = create((set) => ({
  categoryList: [],
  entryList: [],
  timeStampList: [],
  videoList: [],
  loadingState: true,
  timeStampLoadingState: true,

  fetchData: (collectionName, setStateKey, setLoadingStateKey = null) => {
    let collectionRef = collection(db, collectionName);

    // If fetching entries, order by entryDate and limit to 10
    if (collectionName === "entry") {
      collectionRef = query(
        collectionRef,
        orderBy("entryDate", "desc"),
        limit(10)
      );
    }

    onSnapshot(collectionRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      set((state) => ({
        [setStateKey]: data,
        ...(setLoadingStateKey && { [setLoadingStateKey]: false }),
      }));
    });
  },

  fetchCategoryEntries: (categoryTitle) => {
    if (!categoryTitle) return;

    const collectionRef = collection(db, "entry");
    const q = query(
      collectionRef,
      where("categoryId", "==", categoryTitle),
      orderBy("entryDate", "desc")
    );

    onSnapshot(q, (snapshot) => {
      const entries = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ entryList: entries });
    });
  },

  initializeData: () => {
    const fetchData = useStore.getState().fetchData;
    fetchData("category", "categoryList");
    fetchData("entry", "entryList");
    fetchData("timestamps", "timeStampList", "timeStampLoadingState");
    fetchData("videos", "videoList", "loadingState");
  },
}));

export default useStore;
